import React from 'react';
import {
  BellOutlined,
  CompassOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  ShopOutlined,
  GiftOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  LogoutOutlined,
  SettingOutlined,
  ShoppingOutlined,
  ApartmentOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  BlockOutlined,
  FileOutlined,
  MessageOutlined,
  PayCircleFilled,
  ReadOutlined
} from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  accessRoles?: string;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.nft-dashboard',
    key: 'nft-dashboard',
    // TODO use path variable
    url: '/',
    icon: <NftIcon />,
  },
  {
    title: 'common.nft-dashboard',
    key: 'dashboard',
    icon: <HomeOutlined />,
    accessRoles: 'A, S',
  },
  {
    title: 'common.inventory',
    key: 'inventory',
    icon: <AppstoreOutlined />,
    accessRoles: 'A, S',
    children: [
      {
        title: 'common.allProduct',
        key: 'all-product',
        url: '/product/all-product',
        accessRoles: 'A, S,V',
      },
      {
        title: 'common.stock',
        key: 'all-vendor-product',
        url: '/product/all-vendor-product',
        accessRoles: 'A, S,V',
      },
      {
        title: 'common.parentcategory',
        key: 'parentcategory-list',
        url: '/category/parentcategory-list',
        accessRoles: 'A, S',
      },
      {
        title: 'common.subcategory',
        key: 'subcategory-list',
        url: '/category/subcategory-list',
        accessRoles: 'A, S',
      },
      {
        title: 'common.brand',
        key: 'all-brand',
        url: '/brand/brand-list',
        accessRoles: 'A, S',
      },
    ],
  },
  {
    title: 'common.order',
    key: 'order',
    icon: <ShoppingCartOutlined />,
    accessRoles: 'A, S, V',
    children: [
      {
        title: 'common.allOrder',
        key: 'order-list',
        url: '/order/order-list',
        accessRoles: 'A, S, V',
      },
      {
        title: 'common.fulfillmentOrders',
        key: 'fulfillment-list',
        url: '/order/fulfillment-list',
        accessRoles: 'A, S, V',
      },
      // {
      //   title: 'common.allSellerOrder',
      //   key: 'all-seller-order',
      //   url: '/order/all-seller-order',
      //   accessRoles: 'A, S, V',
      // },
    ],
  },
  {
    title: 'common.offers',
    key: 'offers',
    url: '/offers/offers-list',
    icon: <GiftOutlined />,
    accessRoles: 'A, S',
  },
  {
    title: 'common.customersList',
    key: 'customersList',
    url: 'customers-List',
    icon: <TeamOutlined />,
    accessRoles: 'A, S',
  },
  {
    title: 'common.manageVendor',
    key: 'managevendor',
    url: '/vendors/all-vendor',
    icon: <UsergroupAddOutlined />,
    accessRoles: 'A, S',
  },
  {
    title: 'common.user',
    key: 'user',
    url: '/user/user-list',
    icon: <UserOutlined />,
    accessRoles: 'A, S',
  },
  {
    title: 'common.notification',
    key: 'notification',
    icon: <BellOutlined />,
    accessRoles: 'A, S',
    children: [
      {
        title: 'common.notificationConfiguration',
        key: 'all-templateConfiguration',
        url: '/configuration/add-templateConfiguration',
        accessRoles: 'A, S',
      },
      {
        title: 'common.templateCreation',
        key: 'add-template',
        url: '/template/add-template',
        accessRoles: 'A, S',
      },
      {
        title: 'common.emailConfiguration',
        key: 'emailConfiguration',
        url: 'emailConfig/emailConfig-list',
        accessRoles: 'A, S',
      },
      {
        title: 'common.allMessageProviders',
        key: 'all-addMessageProviders',
        url: '/messageProviders/messageProviders-list',
        accessRoles: 'A, S',
      },
    ],
  },
  {
    title: 'common.blog',
    key: 'blog',
    url: '/blog/blog-list',
    icon: <ReadOutlined />,
    accessRoles: 'A, S',
  },
  {
    title: 'common.setup',
    key: 'uiSetup',
    icon: <SettingOutlined />,
    accessRoles: 'A, S',
    children: [
      {
        title: 'common.company',
        key: 'company',
        url: 'company/add-company',
        icon: <HomeOutlined />,
        accessRoles: 'A, S',
      },
      {
        title: 'common.themeColor',
        key: 'theme-setup',
        url: '/theme/setup-list',
        accessRoles: 'A, S',
      },
      {
        title: 'common.allPromotion',
        key: 'all-promotion',
        url: '/promotion/promotion-list',
        accessRoles: 'A, S',
      },
      {
        title: 'common.allPromotionBanner',
        key: 'all-promotionBanner',
        url: '/promotionBanner/promotionBanner-list',
        accessRoles: 'A, S',
      },
      {
        title: 'Footer Page',
        key: 'footersection-list',
        url: '/category/footersection-list',
        accessRoles: 'A, S',
      },
      {
        title: 'common.allFooters',
        key: 'all-footers',
        url: '/uiSetup/all-footers',
        accessRoles: 'A, S',
      },
      {
        title: 'common.themeConfiguration',
        key: 'themeConfiguration',
        url: 'theme/setTheme',
        accessRoles: 'A, V',
      },
      {
        title: 'common.api',
        key: 'apiConfig',
        url: '/uiSetup/api-config',
        accessRoles: 'A, V',
      },
      {
        title: 'common.paymentProviders',
        key: 'paymentProviders',
        url: 'payment-providers',
        icon: <PayCircleFilled />,
        accessRoles: 'A, S',
      },
      {
        title: 'Shipping',
        key: 'shippingProfileConfiguration',
        url: 'shippingProfile/shippingProfile-list',
        accessRoles: 'A, S',
      },
    ],
  },
  {
    title: 'common.logout',
    key: 'logout',
    url: '/auth/logout',
    icon: <LogoutOutlined />,
    accessRoles: 'A, S, V',
  },
];
