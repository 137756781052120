import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel, ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface ProductState {
  loading: boolean;
  products: ProductModel[] | [];
  product: Product | null;
}

const initialState: ProductState = {
  loading: false,
  products: [],
  product: null,
};

export const productList = createAsyncThunk('/products/product-list', async (vendorId, thunkApi) => {
  try {
    const response = await axios.get<ProductModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/GetAllProductList/VendorId/${vendorId}`,
      {
        params: {
          companyId: getLoggedInCompanyId(),
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const vendorProductList = createAsyncThunk('products/all-vendor-product', async (_, thunkApi) => {
  try {
    const response = await axios.get<ProductModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/Seller/GetSellerProductList`,
      {
        params: {
          vendorId: 1,
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const StockAdjustment = createAsyncThunk('/variant/variant-list', async (values, thunkApi) => {
  try {
    console.log(values);
    const response = await axios.put<BaseResponse>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/Seller/UpdateStockAdjust`,
      null,
      {
        params: {
          productId: values.productId,
          stock: values.stock,
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const addProduct = createAsyncThunk('product/add-product', async (values, thunkApi) => {
  try {
    const response = await axios.post<Product>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/SaveProduct`,
      {...values, companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const uploadProduct = createAsyncThunk('product/upload-product', async (values, thunkApi) => {
  try {
    const response = await axios.post<Product>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/SaveExcelProduct`,
      {...values, companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const productDetails = createAsyncThunk('products/view-product', async (id, thunkApi) => {
  try {
    const response = await axios.get<Product>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/GetProductDetailsById/${id}`,
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const productStatusUpdate = createAsyncThunk('products/update-product-status', async (values, thunkApi) => {
  try {
    const response = await axios.put<BaseResponse>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/UpdateProductStatus`,
      null,
      {
        params: {
          productId: values.productId,
          status: values.status,
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const updateProduct = createAsyncThunk('product/update-product', async (values, thunkApi) => {
  try {
    const response = await axios.put<Product>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/UpdateProduct`,
      {...values, companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const deleteProduct = createAsyncThunk('product/delete-product', async (id, thunkApi) => {
  try {
    const response = await axios.delete<ProductModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/DeleteProduct`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(productList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(productList.fulfilled, (state, action: PayloadAction<ProductModel[]>) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(productList.rejected, (state, action) => {
      state.loading = false;
      state.products = [];
    });
    builder.addCase(vendorProductList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorProductList.fulfilled, (state, action: PayloadAction<ProductModel[]>) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(vendorProductList.rejected, (state, action) => {
      state.loading = false;
      state.products = [];
    });
    builder.addCase(productDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(productDetails.fulfilled, (state, action: PayloadAction<Product>) => {
      state.loading = false;
      state.product = action.payload;
    });
    builder.addCase(productDetails.rejected, (state, action) => {
      state.loading = false;
      state.product = null;
    });
    //upload product
    builder.addCase(uploadProduct.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(uploadProduct.fulfilled, (state, action: PayloadAction<Product>) => {
      state.loading = false;
      state.product = action.payload;
    });
    builder.addCase(uploadProduct.rejected, (state, action) => {
      state.loading = false;
      state.product = null;
    });
    // Save
    // builder.addCase(createLead.pending, (state, action )=> {
    //   state.loading = true;
    // });
    // builder.addCase(createLead.fulfilled,(state, action: PayloadAction<ProductModel>) => {
    //     state.loading = false;
    //     state.products = action.payload.data;
    // });
    // builder.addCase(createLead.rejected, (state, action) => {
    //   state.loading = false;
    //   state.products = [];
    // });
  },
});

export const thunks = {
  productList,
  addProduct,
  uploadProduct,
  productDetails, 
  updateProduct,
  productStatusUpdate,
};

export default productSlice.reducer;
