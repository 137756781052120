import React from 'react';
import * as S from './References.styles';
import { FacebookOutlined } from '@ant-design/icons';

const FacebookIcon = S.withStyles(FacebookOutlined);

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Icons>
        {/* <a href="https://www.facebook.com/arragehere" target="_blank" rel="noreferrer">
          <FacebookIcon />
        </a> */}
      </S.Icons>
      <S.Text style={{ fontSize: '12px', marginRight: '533px', whiteSpace: 'nowrap' }}>
  <span>
    Copyright &copy; Makinus. All rights reserved.
  </span>
       
      </S.Text>
      <S.Text style={{fontSize:'12px'}}>
        Powered by{' '}
        <a href="https://makinus.com" target="_blank" rel="noreferrer">
          Makinus{' '}
        </a>
        in 2023 &copy;
      </S.Text>
    </S.ReferencesWrapper>
  );
};
